import { onCLS, onFCP, onLCP, onTTFB } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: (metric: any) => void) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    // Handling events for web vitals
    onCLS(onPerfEntry); // CLS event handler
    onFCP(onPerfEntry); // FCP event handler
    onLCP(onPerfEntry); // LCP event handler
    onTTFB(onPerfEntry); // TTFB event handler
  }
};

export default reportWebVitals;
