import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router-dom';
import InvoiceNumber from './Views/InvoiceNumber';

export default function RouteHandler() {
  return (
    <Routes>
      <Route path='/' element={<InvoiceNumber />} />
    </Routes>
  );
}
