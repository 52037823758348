import React from 'react';
import SplitPdf from '../Components/SplitPdf';
import '../css/Main.scss';

type Props = {};

const InvoiceNumber = (props: Props) => {
  return (
    <div className='container'>
      <SplitPdf />
      {/* More detailed text */}
      <div className='invoice'>
        <p className='mt-5 text-center'>
          Welcome to the PDF Splitter! This powerful tool allows you to easily
          split your PDF documents based on specific criteria. Whether you're
          dealing with long invoices, reports, or contracts, our tool can help
          you separate individual pages or sections from a multi-page PDF
          quickly and efficiently.
        </p>

        <p className='feature-description'>
          One of the standout features of our PDF splitter is its ability to
          automatically <strong>read the invoice number</strong> from your
          document and use it to <strong>rename each split file</strong>. This
          ensures that you don't have to manually name each file—our tool does
          it for you!
        </p>

        <p className='how-it-works'>
          Here's how it works:
          <ul className='how-it-works-list'>
            <li>Upload your PDF document to the tool.</li>
            <li>
              The system scans each page and looks for the invoice number (or
              any other predefined data).
            </li>
            <li>
              Once identified, the invoice number is extracted and used to name
              each new file created from the split PDF.
            </li>
          </ul>
        </p>

        <p className='general-description'>
          Whether you're working with a single invoice or managing multiple PDF
          documents at once, this tool will save you time and effort by
          automating the process of splitting and renaming your files based on
          the content within them.
        </p>

        <p className='call-to-action'>
          This tool is perfect for businesses, freelancers, and anyone who needs
          to manage large amounts of PDF data. It's simple, fast, and free to
          use. Try it out now and see how it works!
        </p>

        <p className='invoice-organization'>
          If you're working with invoices regularly, our PDF splitter can help
          you organize your documents and ensure that each file is properly
          labeled with the relevant information.
        </p>
      </div>
    </div>
  );
};

export default InvoiceNumber;
